<template>
  <div>
    <CashPayListCard search="" />
  </div>
</template>
<script>
import CashPayListCard from "../components/CashPayListCard";
export default {
  components: { CashPayListCard },
  data() {
    return {
      dialog: false,
      search: "",
      currentPage: 1,
      chipTransfer: {},
      searchTypeSelect: { id: -1, value: "All" },
      headers: [
        { text: "From User", value: "fromUserId" },
        { text: "From Chip", value: "fromChip" },
        { text: "User", value: "userId" },
        { text: "User Chip", value: "toChip" },
        { text: "Sended Chip", value: "chip" },
        { text: "Commission", value: "commission" },
        { text: "Ip", value: "ip" },
        { text: "Created Date", value: "createdDate" },
      ],
      searchTypes: [
        { id: 0, value: "All" },
        { id: 1, value: "From User" },
        { id: 2, value: "To User" },
      ],
    };
  },
};
</script>
